<template>
  <div class="container">
	
		<div class="row mt-5">
			<div class="col-md-6">	   
				<div>
					<img src="../assets/web.png" style="width:100%" />
				</div>    
			</div>
		  
			<div class="col-md-6">
				<div class="">
					<h3 class="theme">
						We Provide 
						<span class="theme-color">
							Web Development
						</span>
						<br class="hidden-small"> and Related
						<span class="theme-color">
							Solutions
						</span>
					</h3>
					
					<p>		
						We provide web development solutions tailored to meet your 
						business needs, goals, and context. We develop solutions for 
						business data management & analysis, streamlining digital processes,
						improving customer services and enhanced user experience.
					</p>
					
					<h6 style="color:orange; font-weight: 900;">		
						Our Web Solution Key Features: 
					</h6>
					
					
					<ul class="list">	
						<li>Business data management & analysis</li>
						<li>Streamlined Digital Processes</li>
						<li>Improved Customer Services</li>
						<li>Enhanced User Experience</li>
						<li>+ more</li>
					</ul>  
					
					<div>
						<a href="/solutions/web-application-development" class="green-get-quote-btn">Get Quote</a>
					</div>
					
				</div>
			</div>		  
		</div>
	</div>
	
	<section class="my-5 py-5" style="background-color: #008080">
	<div class="container">	
		<div class="row banner-row">			
			<div class="col-md-6">
				<div class="">
					<h3 class="theme">
						We Develop 
						<span class="orange-color">
							Mobile Application 
						</span>
						<br class="hidden-small"> and 
						Related
						<span class="orange-color">
							Solutions
						</span>
					</h3>
					
					<p>		
						We design and build mobile application tailored to enhanced
						your business service accessibility, customer engagement
						for business efficiency, data collection and brand recognition.
					</p>
					<p>		
						We also ensure that UI/UX designs are impeccable to facilitate 
						intuitiveness and ease of use for customers/clients.
					</p>
					
					<h6 style="color:orange;font-weight: 900;">		
						Our Mobile Application Solution Key Features: 
					</h6>
					
					
					<ul class="list">	
						<li>Enahnced Business Service Accessibility</li>
						<li>Enahnced Customer Engagement</li>
						<li>Seamless Data Collection</li>
						<li>Adequate Brand Recognition</li>
						<li>Intutitive User Interface for Ease of Use</li>
						<li>+ more</li>
					</ul>  
					
					<div>
						<a href="/solutions/mobile-application-development" class="orange-get-quote-btn">Get Quote</a>
					</div> 

					
				</div>
			</div>
			
			<div class="col-md-6">	   
				<div>
					<img src="../assets/mobile-ui.png" style="width:100%" />
				</div>    
			</div>
		  	
		</div>
		
  
	</div>
	
	</section>
	
	<div class="container">	
		<div class="row mt-5">
		
			<div class="col-md-6">	   
				<div>
					<img src="../assets/cloud.png" style="width:100%" />
				</div>    
			</div>
			
			<div class="col-md-6">
				<div class="">
					<h3 class="theme">
						We Provide 
						<span class="theme-color">
							On-Premise Cloud Deployment
						</span>
						<br class="hidden-small"> and  Related
						<span class="theme-color">
							Solutions
						</span>
					</h3>
					
					<p>		
						We offer On-Premise Cloud Deployment and Related Solutions
						for Financial Services providers, Healthcare providers, 
						Manufacturing Industries and Large Enterprises for enhanced 
						security and compliance, customizability and control, 
						cost management, latency and performance, integration with 
						legacy systems and data sovereignty.	
						
					</p>
					<p>		
						Our On-Premise Cloud Solution implementation flow involves 
						Assessment and Planning, Deployment, Integration and Testing,
						and Management and Maintenance. 
					</p>
					
					<h6 style="color:orange; font-weight: 900;">		
						Our On-Premise Cloud Deployment Solution Key Features: 
					</h6>					
					
					<ul class="list">	
						<li>Enhanced Security and Compliance</li>
						<li>Customizability and Control</li>
						<li>Cost Management</li>
						<li>Latency and Performance</li>
						<li>Integration with Legacy Systems</li>
						<li>Data Sovereignty</li>
						<li>And more +</li>
					</ul>
					
					<div>
						<a href="/solutions/on-premise-cloud-deployment" class="green-get-quote-btn">Get Quote</a>
					</div> 
					
				</div>
			</div>
			
			
		  	
		</div>
		
  
	</div>
  
 
  
</template>

<script>
export default {
  name: 'Solution'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

.orange-color{
	color: orange;	
}

p{
	margin-bottom: 1rem !important;
	color: #fff;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #eee;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}

.green-get-quote-btn{
	display: inline-block;
	background-color: #008080;
	color: #fff;
	padding: 10px 21px;
	border-radius:7px;
}


.orange-get-quote-btn{
	display: inline-block;
	background-color: orange;
	color: #fff;
	padding: 10px 21px;
	border-radius:7px;
}

@media only screen and ( max-width:900px){
	.banner-row{
		display: flex !important;
		flex-flow: column-reverse !important;
	}
	.hidden-small{
		display: none;
	}
}
	
	


</style>
