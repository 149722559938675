<template>
  <div class="row flex-start mt-5">
		<div class="col-md-6">	   
		<div style="background-color: #eee; padding:1rem 2rem; border-radius: 14px;">	   
			
			<h3 class="theme">
				<span class="theme-color">
					React Native and Vue.js
				</span>
				<span class="default-color">
					Engineer Position
				</span>	
			</h3>
			
			
			<p>		
				We have open positions for <strong>React Native</strong> and <strong>Vue.js</strong> Developers. 
				The employment is on part-time bases with a monthly take home of 
				negotiable amount which could be up to <strong>One Hundred and Eighty Thousand
				( N180,000.00 )</strong>.				
			</p>
			
			<p>		
				Applicants are required to have minimum of 2+ years of experience working with
				server side javascript (Node.js), Express.js, JWT, React Native(Expo), 
				Vue.js (vue-cli) and good command of CSS. Knowledge in Python (Flask) and 
				GraphQL would be an added advantage.
			</p>
			
			<p>		
				Job responsiblity includes developing and maintaining a partly developed
				React Native, Vue Web Application and its server-side code bases.	
			</p>
			
			<h5>
				Job Requirements:
			</h5>
			<ul class="list">	
				<li>Minimum of 2 years working with JavaScript</li>
				<li>Good understanding of RESTful API - Express.js</li>
				<li>Good CSS command</li>
				<li>Proificiency in Vue.js</li>
				<li>Proificiency in React Native</li>
				<li>Expreince with JWT</li>
				<li>Expreince with Git</li>
				<li>Must be available for twice weekly meetup via Zoom or Google Meet</li>
				<li>Experience with GraphQL and Python (Flask) would be an added advantage</li>
			</ul> 

			<p>		
				Fill the <strong>Application Form</strong> to get interview appointment.	
			</p>


			
		</div>
		</div>
	  
		<div class="col-md-6">
			
			<div class="">
				<h3 class="theme mt-0">
					<span class="theme-color">
						Application
					</span>
					Form				
				</h3>
				<div style="margin-top:0rem"> 
					<form @submit.prevent="submitForm">
						<div class="row">
							<div class="col-md-6">
								<small class="field-label">
									First Name <span class="asterik">*</span>
								</small>
								<div class="form-group">
									<input type="text" class="form-control" v-model="firstname" ref="firstname" name="firstname" placeholder="First name" />
								</div>
								<span v-if="firstnameError" class="errorText">
									Enter first name
								</span>
							</div>
							<div class="col-md-6">
								<small class="field-label">
									Last Name
								</small>
								<div class="form-group">
									<input type="text" class="form-control" v-model="lastname" ref="lastname" name="lastname" placeholder="Last name" />
								</div>
							</div>							
						</div>
						<small class="field-label">
							Email  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="email" class="form-control" v-model="email" ref="email" name="email" placeholder="Email address" />
						</div>
						<span v-if="emailError" class="errorText">
							Enter email address
						</span>
						
						<small class="field-label">
							Phone Number  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="tel" class="form-control" v-model="phone" ref="phone" name="phone" placeholder="Phone number" />
						</div>
						<span v-if="phoneError" class="errorText">
							Enter phone
						</span>
				
						<small class="field-label">
							Years of experience <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<select class="form-control" v-model="year_of_experience" ref="year-of-experience" name="year-of-experience">
								<option value="">Select</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7+">7+</option>
							</select>
						</div>
						<span v-if="year_of_experienceError" class="errorText">
							Select years of experience
						</span>
						
								
						<div class="form-group">
							<button v-if="!processing" type="submit" class="btn btn-primary">
								Apply Now!
							</button>
							<button v-if="processing" type="submit" class="btn btn-primary">
								Processing ...
							</button>
						</div>
					</form>
					
					
				</div>   
			</div>
		</div>
		
	  
  </div>
  
 
  
</template>

<script>
import Cookies from 'js-cookie';
import { graphqlNoAuthRequest } from '../utils/client';
export default {
  name: 'Job',
  data(){
	return {
		firstname: '',
		firstnameError: false,
		lastname: '',
		lastnameError: false,
		email: '',
		emailError: false,
		phone: '',
		phoneError: false,		
		year_of_experience: '2',
		year_of_experienceError: false,
		processing: false,
	}
  },
  methods: {
	submitForm(){
		//	const clean = DOMPurify.sanitize('<s>hello</s>');
		this.firstname 	= DOMPurify.sanitize( this.firstname );
		this.lastname 	= DOMPurify.sanitize( this.lastname );
		this.email 		= DOMPurify.sanitize( this.email );
		this.phone 		= DOMPurify.sanitize( this.phone );
		this.year_of_experienceError 		= DOMPurify.sanitize( this.year_of_experienceError );
			
		let isValidSubmission = true;
		if( this.firstname == '' ){
			this.firstnameError = true;
			isValidSubmission = false;
		}else{
			this.firstnameError = false;
		}			
		
		if( this.email == '' ){
			this.emailError = true;
			isValidSubmission = false;
		}else{
			this.emailError = false;
		}
		
		if( this.phone == '' ){
			this.phoneError = true;
			isValidSubmission = false;
		}else{
			this.phoneError = false;
		}
		
		
		if( this.year_of_experience == '' ){
			this.year_of_experienceError = true;
			isValidSubmission = false;
		}else{
			this.year_of_experienceError = false;
		}

		if( isValidSubmission ){
			this.processing = true;
			( async() => {
				try{
					const queryString = `mutation( $firstname: String!, $lastname: String, $email: String!, $phone: String!, $years_of_experience: String! ){
						createJobApplicant( firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, years_of_experience: $years_of_experience ){
							id
							firstname
							lastname
							email
							phone
						}
					}`;
					
					const variable = {
						firstname: this.firstname,
						lastname: this.lastname,
						email: this.email,
						phone: this.phone,
						years_of_experience: this.year_of_experience
					};
					
					const request = await graphqlNoAuthRequest( queryString, variable );
					if( request.error ){
						console.log( JSON.parse( request.error ) );
					}else{
						console.log( request );
						Cookies.set( 'applicant', JSON.stringify( request.data ), { expires: ( 1/24 ) } );
						
						setTimeout( () => {
							this.processing = true;
							this.$router.push( '/job-application-status' );
						}, 3000 );
						
					}
					
				}catch( e ){
					console.log( e );
				}
				
			
			} )();		
			
		}
		
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

body{
	background-image: url( ../assets/about-us.png ) !important;
}


.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #444;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #444;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	

.field-label{
	color: #fff;
}

input, select, button{
	display: block;
	padding: 0 14px;
	height: 42px;
	width:100%;
	outline: none !important;
}

button.btn-primary{
	margin-top:2rem;
	background-color: #008080;
	border:1px solid #008080;
	color: #fff;
}

.form-group{
	margin-bottom: 0px !important;
}

.asterik{
	color: #f00;
}

.field-label{
	display: inline-block;
	width: 100%;
	margin-top: 1rem;
}

.errorText{
	color: #f00;
	font-size: 12px;
	margin-top: 0px !important;	
}
.default-color{
	color: #444
}

</style>
