<template>
	<Solution />
	
</template>

<script>

import Solution from '../components/Solution.vue';
export default {
	name: 'Trainings',
	components: {
		Solution
	},	
	data(){
		return {};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>
<style scoped>


</style>