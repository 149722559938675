<template>

	<header>
		<div class="container">
			<div id="nav">
				<div id="logo">
					<div>
						<router-link to="/home">
							<img alt="Vue logo" src="./assets/logo-white.svg" width="210">
						</router-link>
					</div>
					<div id="hamburger-wrapper">
						<span @click="toggleMenu" id="hamburger" class="fa fa-bars"></span>
					</div>
					
				</div>
				<div ref="menubar" id="menubar">
					<ul>
						<li>
							<router-link to="/home">
								Home
							</router-link>
						</li>
						
						<li>
							<router-link :to="{ name: 'About' }">
								About
							</router-link>
						</li>
						
						<li>
							<router-link to="/solutions">
								Solutions
							</router-link>
						</li>
						
						<li>
							<router-link :to="{ name: 'Trainings' }">
								Trainings
							</router-link>
						</li>
						
						<li>
							<router-link to="/job">
								Job
							</router-link>
						</li>
						<li>
							<router-link to="/get-in-touch">
								Get in Touch
							</router-link>
						</li>
						<li>
							<router-link to="/trainee/login">
								Login
							</router-link>
						</li>
						
						
					</ul>
				</div>
			</div>
		</div>
	</header>
	
	<router-view />

	<footer style="">
		<div class="container">
			<div class="row">
				<div class="col-md-4 col-sm-12">
					<div>
						<router-link to="/home">
							<img alt="Vue logo" src="./assets/logo-mixed.svg" width="180">
						</router-link>
					</div>
				</div>
				<div style="border:0px solid #f00" class="col col-xs-12">
					<div class="footer-copy-links">
						<span class="footer-legals">
							<a href="/terms-and-conditions">
								Terms and Conditions
							</a>
						</span>
						<span class="footer-legals">
							<a href="/privacy-policy">
								Privacy Policy
							</a>
						</span>
						<span class="footer-legals">
							<a href="/refund-policy">
								Refund Policy
							</a>
						</span>
						
						<span class="footer-social-media-icon">
							<a target="_blank" href="https://x.com/@westribbon_sol">
								<span class="fab fa-x"></span>
								
							</a>
						</span>
						
						<span class="footer-social-media-icon">
							<a target="_blank" href="https://www.facebook.com/westribbon/">
								<span class="fab fa-facebook"></span>
								
							</a>
						</span>
						
						
					</div>
				</div>
				
			</div>
		</div>
		
		<div class="container mt-5">
			<div class="row">
				<div class="col-md-12 copy-section">
					<small class="centered-text">
						<a target="_blank" href="https://westribbon.com.ng">
							&copy; 2024. Westribon All Rights Reserved
						</a>
					</small>
				</div>
			</div>
		</div>
		
		
	</footer>
	
</template>
<script>
export default {
	setup(){
		console.log( "Westribon Lab 1.0!" );			
	},
	methods: {
		toggleMenu(){
			const menubar 			= this.$refs.menubar;
			menubar.style.marginTop = '1rem';
			
			if( window.getComputedStyle( menubar ).display == 'none'){
				menubar.style.display = 'block';
			}else{
				menubar.style.display = 'none';
			}
			
		}
	}, 
	mounted(){
		
	},
	computed: {
		
	},
}
</script>
<style>
html{
	margin:0 !important;	
	padding:0 !important;	
	box-sizing: border-box !important;
}

.lato-thin {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.lato-light {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.lato-regular {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.lato-bold {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.lato-black {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.lato-thin-italic {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.lato-light-italic {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.lato-regular-italic {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.lato-bold-italic {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.lato-black-italic {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: italic;
}


body{
	margin:0px !important;
	padding:0px !important;
	background-image: url(./assets/bg.jpg);
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;	
}



p, h1, h2, h3, h4{
	margin:0 !important;;
	padding:0 !important;;
}

/* width */
::-webkit-scrollbar {
	width: 3px;
	height:3px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 7px grey; 
	background: #fff; 
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #008080; 
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #0c8d66; 
}


a{
	text-decoration: none !important;
	color: #fff !important;
}


#app {
	display: block;
	border: 0px solid #f00;
	margin: 0;
	/*
	font-family: Avenir, Helvetica, Arial, sans-serif;	
	*/
	font-family: "Lato", sans-serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: cente;
	color: #2c3e50;
}

header{
	margin-top: 1.5rem;
	background-color: transparent;
	/*
		background-image: url( ./assets/transparent-bg.png );
		background-repeat: repeat;
	*/
	
}

#nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	background-color: ;
	padding:14px 0;	
}

#hamburger-wrapper{
	display: none;
}

#logo{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#hamburger{
	font-size:1.7rem;
	color: #fff;
}

#wrapper{
	width: 1200px;
	margin: auto;	
}

#nav a.router-link-exact-active{
	color: #008080 !important;
	font-weight: bold;
}

#menubar{
	flex: 1
}

#menubar > ul{
	margin:0;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;	
}

#menubar > ul > li{
	list-style-type: none;
}

#menubar > ul > li a{
	padding:14px;
}

.slide-theme{
	margin:2rem 0 1rem 0 !important;
	display: block;
	box-sizing: border-box;	
}


#router-nav {
  margin-bottom:28px;
}

#router-nav a{
	text-decoration: none;
}

.training-title{
	color: #008080;
	font-size:1.5rem;
}

.training-date{
	font-size:14px;
	margin-bottom: 21px;
}

.training-level-price{
	font-size:28px;
	margin-bottom: 21px;
	font-weight: 900;	
}

footer{
	display: block;
	margin-top: 7rem;
	min-height: 70px; 
	background-color: #008080; 
	padding:21px 0; 
}

.footer-social-media-icon{
	display: inline-block;
	margin: 0 14px;
}

.footer-legals{
	display: inline-block;
	margin:0 14px;
}
	

.icon-text{
	display: none;
}

.footer-copy-links{
	text-align:right;
}

.copy-section{
	text-align:center;
	border-top:1px solid #1fa3a3;
	padding:14px 0;
}
.centered-texts{
	text-align:center;
}

@media screen and (max-width: 992px) {
	#nav{
		display: block;
		margin: auto;
		background-color: ;
		padding:14px 0;			
	}
	
	#menubar{
		flex: none;
		display: none;
	}

	#menubar > ul{
		margin:0;
		padding: 0;
		display: block;
		justify-content: flex-end;
		align-items: center;	
	}

	#menubar > ul > li{
		list-style-type: none;
		border-bottom:1px dotted #008080;
	}

	#menubar > ul > li a{
		display: block;
		padding:7px 0px;
	}
	
	.main-slide-theme{
		font-size: 2.5rem;
	}
	
	
	.training-title{
		color: #008080;
		font-size:2rem;
	}

	.training-date{
		font-size:14px;
		margin-bottom: 21px;
	}

	.training-period{
		font-size:21px;
		margin-bottom: 21px;
	}

	.training-level-price{
		font-size:35px;
		margin-bottom: 21px;		
	}
	
	#hamburger-wrapper{
		display: inline-block;
	}
	
	.footer-copy-links{
		text-align:left;
	}
	.footer-copy-links span{
		display: block;
		margin: 14px 0;
	}
	
	.icon-text{
		display: inline-block;
		margin-right: 21px;
	}
	
	

}

</style>
