<template>
	<div class="container">
		<TermsAndConditions />
	</div>
	
</template>

<script>
//	import { useMeta } from 'vue-meta';
import TermsAndConditions from '../components/TermsAndConditions.vue';
export default {
	name: 'TermsAndConditionPage',
	components: {
		TermsAndConditions
	},	
	data(){
		return {
			title: 'Terms and Conditions',
			description: 'Terms and Conditions'
		};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
		//	useMeta({
		//		title: this.title,
		//		meta: [
		//			{
		//				name: 'description',
		//				content: this.description
		//			},
		//			{
		//				property: 'og:title',
		//				content: this.title
		//			},
		//			{
		//				property: 'og:description',
		//				content: this.description
		//			}
		//		]
		//	});
	}, 
	setup(){
	
	}
	
}



</script>
<style scoped>
body{
	background-image: url( ../assets/about-us.png ) !important;
}

</style>