<template>	
	<div class="row">
		<div class="col-md-12">
		
			<div class="slide-theme">
				<h1 class="main-slide-theme mb-3">
					Get a Promising <span class="lato-bold">GOOD</span> Life   
					<br>and <span class="lato-bold">FUTURE</span> 
					Proof Tech Career 
				</h1>
				
				<h3 class="sub-slide-theme">
					You don't have to be anyone's employee. 					
				</h3>
			</div>
			
		</div>				
	</div>
	
	<div class="row mb-5">
	
		<div class="col-md-9">			
			<div class="container">			
				<ul class="bullet-list row">
					<li class="col-md-6">
						Become your own boss
					</li>
					<li class="col-md-6">
						Work from home or anywhere
					</li>
					<li class="col-md-6">
						Be in charge of your time
					</li>
					<li class="col-md-6">
						Build promising dreams
					</li>
					<li class="col-md-6">
						Have a good life
					</li>
					<li class="col-md-6">
						And more +
					</li>
					
				</ul>			
			</div>
		</div>
		
		
		
	</div>
	


	<h1 class="sub-slide-theme mt-3 mb-2">
		Top Trending Trainings from 
		<span class="lato-bold">Westribbon</span> Lab
	</h1>
	
	<div class="row">
		<div class="col-md-3 card-col" v-for="training in homeTrainings" :key="training.id">
			<div class="cards" :data="training.bg">
				<div class="training-info">
					<div class="training-content">
						<h3 class="training-title">
							{{ training.title }}
						</h3>
						<p class="training-date">
							<small>Level: {{ training.level }}</small>
							<span class="mx-2">
								|
							</span>
							<small>Stack: {{ training.stack }}</small>
						</p>
						
						<!--
						<p class="training-date">
							Starting {{ training.start_date }}
						</p>
						-->
						
						<p class="training-period mt-2">
							Period: {{ training.period }}
						</p>
						
						<div class="training-level-price lato-bold">
							<small>{{ training.price }}</small>
						</div>
						
						<div class="btn-position">
							<span class="btn-wrapper">
								<router-link :to="{ name: 'Training', params:{ slug: training.slug }  }">
									More info ...
								</router-link>	
							</span>	
						</div>	
						
					</div>
				</div>
			</div>
		</div>
	</div>
	

</template>

<script>
export default {
	name: 'HomePlaceholder',	
	data(){
		return {
			trainings: [
				{
					id: 1,
					title: "Web Frontend Development",
					slug: "web-frontend-development",
					level: "Beginner",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product1.png",
					show_on_home: true
				},
				{
					id: 2,
					title: "Backend Development",
					slug: "backend-development",
					level: "Beginner",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product2.png",
					show_on_home: true
				},
				{
					id: 3,
					title: "Advanced Backend Development",
					slug: "backend-development-advanced",
					level: "Advanced",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product3.png",
					show_on_home: true
				},
				{
					id: 3,
					title: "Advanced Full Stack Web Development",
					slug: "backend-development-advanced",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N500,000.00",
					show_on_home: false
				},
				{
					id: 4,
					title: "Mobile Application Development with React Native",
					slug: "mobile-application-development-with-react-native",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N350,000.00",
					bg: "../../assets/product4.png",
					show_on_home: true
				},
				{
					id: 4,
					title: "GraphQL Development with JavaScript",
					slug: "mobile-application-development-with-react-native",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					show_on_home: false
				}				
			]
		};
	},
	methods: {
	
	},
	computed: {
		homeTrainings(){
			let data = this.trainings.reverse().filter( training => training.show_on_home  );				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	},	
	setup(){
		const cards = document.querySelectorAll( ".cards" );
		for( let card of cards ){
			card.style.backgroundImage = `url( ${ card.getAttribute( "data" ) } )`;
		}
	},
}



</script>

<style>
.trainings-section{
	display: flex;
	margin-bottom: 7rem;
	
}

.cards{
	background-color: #fff;	
	background-size: cover;	
	background-position: right;	
	min-height: 520px;
	position:relative !important;
	top: 0px;
	border-radius:14px;
	margin:10px 0rem;
}

.training-info{
	position: absolute !important;
	bottom: 0px !important;
	left: 0px !important;	
	background-color: tranparent;
	width: 100%;
	border-radius: 14px;
	background-image: url( ../assets/transparent-bg.png );
	background-repeat: repeat;
}

.training-content{
	margin:2rem 14px .5rem 14px;
	min-height: 240px;
	padding-bottom:21px;
}

.btn-position{
	position: absolute;
	bottom: 14px;
	border: 0px solid #f00;
	
}


.btn-wrapper{
	display: inline-block;
	padding: 7px 21px;	
	background-color: #008080;
	border-radius:10px;	
}

.btn-wrapper > a{
	font-size:14px;
}

.brand-text{
	font-weight: bold;
}

.card-col:nth-child(1) > .cards{
	background-image: url( ../assets/product1.png );
}

.card-col:nth-child(2) > .cards{
	background-image: url( ../assets/product2.png );
}

.card-col:nth-child(3) > .cards{
	background-image: url( ../assets/product3.png );
}

.card-col:nth-child(4) > .cards{
	background-image: url( ../assets/product4.png );
}

.btn-wrapper a{
	color: #fff;
}


.main-slide-theme{
	color: #fff;
	font-size:3.0rem;
	font-weight: 300;	
}

.sub-slide-theme{
	color: #fff;
	font-size:1.5rem;
	font-weight: 300;
	margin: 1rem 0;
	line-height: 2.5rem
}

.bullet-list{
	margin: 0 0 0 0;
	padding:14px 3px;	
}

.bullet-list > li{
	color: #ccc !important;
	line-height: 21px;
	font-size: 14px;
	list-style-image:  url( ../assets/bullet.png );
}




@media screen and (max-width: 992px) {
  
}


</style>