<template>
	<div class="container">
		<AboutUs />
	</div>
	
</template>

<script>
//	import { useMeta } from 'vue-meta';
import AboutUs from '../components/AboutUs.vue';
export default {
	name: 'Trainings',
	components: {
		AboutUs
	},	
	data(){
		return {
			title: 'Get to know us',
			description: 'Get to know us'
		};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
		//	useMeta({
		//		title: this.title,
		//		meta: [
		//			{
		//				name: 'description',
		//				content: this.description
		//			},
		//			{
		//				property: 'og:title',
		//				content: this.title
		//			},
		//			{
		//				property: 'og:description',
		//				content: this.description
		//			}
		//		]
		//	});
	}, 
	setup(){
	
	}
	
}



</script>
<style scoped>
body{
	background-image: url( ../assets/about-us.png ) !important;
}

</style>