import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//	import VueMeta  from 'vue-meta';
import DOMPurify from 'isomorphic-dompurify';


//	import { graphqlNoAuthRequest } from './utils/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'; 
import 'material-design-icons-iconfont/dist/material-design-icons.css';


const app = createApp( App );
app.use( router );
//	app.use( VueMeta  );
app.mount( '#app' );
