<template>
  <div class="row justify-content-center mt-5">  
		<div class="col-md-9">
			<div class="">
				<h1 class="theme">
					Terms and Conditions
				</h1>
				
				
				<p>		
					These terms and conditions outline the rules and regulations 
					for the use of Westribbon's Website and services.
				</p>
				
				<p>					
					By accessing this website and/or using our services, we assume 
					you accept these terms and conditions. Do not continue to use 
					Westribbon if you do not agree to all of the terms and conditions 
					stated on this page.
				</p>
				
				<h4 class="theme">
					1. Definitions
				</h4>
				
				
				<ul class="list">	
					<li>
						<strong>Company</strong>: Refers to Westribbon, the provider of the website and services.
					</li>
					<li>
						<strong>User</strong>: Refers to any individual or entity who accesses the website and/or 
						uses the services provided by Westribbon.
					</li>
					<li>
						<strong>Services</strong>: Refers to all products and services provided by Westribbon.
					</li>
				</ul> 
				
				<h4 class="theme">
					2. Use of the Website and Services
				</h4>
				<ul class="list">	
					<li>
						You must be at least 18 years of age to use our website and 
						services.
					</li>
					<li>
						By using our website and services, you warrant that you have 
						the legal capacity to enter into a binding contract and agree 
						to comply with these terms.
					</li>
					<li>
						Unauthorized use of this website may give rise to a claim for 
						damages and/or be a criminal offense.
					</li>
				</ul> 
				
				
				<h4 class="theme">
					3. Intellectual Property Rights
				</h4>
				<ul class="list">
					<li>
						Unless otherwise stated, Westribbon owns the intellectual 
						property rights for all material on this website.
					</li>
					<li>
						You may access this from Westribbon for your own personal 
						use subject to restrictions set in these terms and 
						conditions.
					</li>
				</ul> 
				
				
				
				<h4 class="theme">
					4. User Accounts
				</h4>
				<ul class="list">
					<li>
						To access certain features of our website, you may be required to create an account.
					</li>
					<li>
						You must provide accurate and complete information and keep your account information updated.
					</li>
					<li>
						You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
					</li>
				</ul> 
				
				<h4 class="theme">
					5. Prohibited Activities
				</h4>
				<p>Users may not:</p>
				<ul class="list">
					<li>
						Engage in any unlawful activities.
					</li>
					<li>
						Use the website in any way that may cause damage to the website 
						or impair the availability or accessibility of the website.
					</li>
					<li>
						Use the website to transmit or send unsolicited commercial 
						communications.
					</li>
				</ul> 
				
				
				<h4 class="theme">
					6. Limitation of Liability
				</h4>
				<p>Users may not:</p>
				<ul class="list">
					<li>
						Westribbon will not be liable for any loss or damage of any 
						nature.
					</li>
					<li>
						We do not guarantee that our services will be error-free or 
						uninterrupted.
					</li>
					<li>
						We reserve the right to modify or discontinue our services at 
						any time without notice.
					</li>
				</ul> 
				
				
				<h4 class="theme">
					7. Privacy Policy
				</h4>
				<p>
					Your use of our website and services is also governed by our 
					Privacy Policy. Please review our Privacy Policy, which explains 
					how we collect, use, and protect your personal information.
				</p>
				
				
				
				<h4 class="theme">
					8. Changes to Terms and Conditions
				</h4>
				<p>
					Westribbon reserves the right to revise these terms and conditions 
					at any time. By using this website, you are expected to review 
					these terms regularly to ensure you understand all terms and 
					conditions governing the use of this website.
				</p>
				
				
				
				<h4 class="theme">
					9. Governing Law
				</h4>
				<p>
					These terms and conditions are governed by and construed in 
					accordance with the laws of Federal Government of Nigeria(FGN), and you 
					irrevocably submit to the exclusive jurisdiction of the courts in 
					that location.

				</p>
				
				
				
				
				<h4 class="theme">
					10. Contact Information
				</h4>
				<p>
					You may contact us through hi@westribbon.com.ng
				</p>
				
				
				
				
				
				  
			</div>
		</div>
		
	  
  </div>
  
 
  
</template>

<script>
export default {
  name: 'TermsAndCondition'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #fff;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #eee;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	


</style>
