<template>
  <div class="row mt-5">
		<div class="col-md-7">	   
			<div>
				<img src="../assets/about-us-1.png" style="width:100%" />
			</div>    
		</div>
	  
		<div class="col-md-5">
			<div class="">
				<h3 class="theme">
					We Provide 
					<span class="theme-color">
						Technological Trainings
					</span>
					<br> and 
					<span class="theme-color">
						Solutions
					</span>
				</h3>
				
				<p>		
					WestRibbon is a Technological Training and Solution provider. 
					We cover key areas in web, mobile and desktop application development. 
					
				</p>
				<p>		
					We offer top-notch Technological Trainings and Solutions to individuals 
					and entities such as schools, hotels, NGOs and general business organizations. 
				</p>
				
				
				<ul class="list">	
					<li>We train to be self-reliant</li>
					<li>We build application to solve key organizational problems</li>
					<li>We Deploy application to scale against demands</li>
					<li>And more +</li>
				</ul>    
			</div>
		</div>
		
	  
  </div>
  
 
  
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #fff;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #eee;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	


</style>
