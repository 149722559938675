<template>	
	<div class="container">
		<HomePlaceholder />
	</div>
</template>

<script>

import HomePlaceholder from '../components/HomePlaceholder.vue';
export default {
	name: 'Trainings',
	components: {
		HomePlaceholder
	},	
	data(){
		return {};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>

<style>
.trainings-section{
	display: flex;
	margin-bottom: 7rem;
	
}

.cards{
	background-color: #fff;	
	background-size: cover;	
	background-position: right;	
	min-height: 520px;
	position:relative !important;
	top: 0px;
	border-radius:14px;
	margin:10px 0rem;
}

.training-info{
	position: absolute !important;
	bottom: 0px !important;
	left: 0px !important;	
	background-color: tranparent;
	width: 100%;
	border-radius: 14px;
	background-image: url( ../assets/transparent-bg.png );
	background-repeat: repeat;
}

.training-content{
	margin:2rem 14px .5rem 14px;
	min-height: 240px;
	padding-bottom:21px;
}

.btn-position{
	position: absolute;
	bottom: 14px;
	border: 0px solid #f00;
	
}


.btn-wrapper{
	display: inline-block;
	padding: 7px 21px;	
	background-color: #008080;
	border-radius:10px;	
}

.btn-wrapper > a{
	font-size:14px;
}

.brand-text{
	font-weight: bold;
}

.card-col:nth-child(1) > .cards{
	background-image: url( ../assets/product1.png );
}

.card-col:nth-child(2) > .cards{
	background-image: url( ../assets/product2.png );
}

.card-col:nth-child(3) > .cards{
	background-image: url( ../assets/product3.png );
}

.card-col:nth-child(4) > .cards{
	background-image: url( ../assets/product4.png );
}

.btn-wrapper a{
	color: #fff;
}



@media screen and (max-width: 992px) {
  
}


</style>