<template>
	<section style="margin-top:4rem; min-height:280px;">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-7">
					<h3 style="color: #fff; text-align:center;">
						We are currently not accpeting applications for trainings
					</h3>
					<h5 class="mt-3" style="color: #fff; text-align:center;">
						Please, check later.
					</h5>
					
					<hr>
					
					<p style="color: #fff; text-align:center;">
						Have a need to contact us? 
						<a href="/get-in-touch" style="color: orange !important;"> Get in touch</a>
					</p>
					<!--
					<small>
						{{ slug }} 
					</small>
					<p>
						Title: {{ title }}
					</p>
					<p>
						Level: {{ level }}
					</p>
					<p>
						Period: {{ period }}
					</p>
					<p>
						Start Date: {{ start_date }}
					</p>
					<p>
						Price: {{ price }}
					</p>
					-->
				</div>
			</div>
		</div>
	</section>
	
</template>

<script>
export default {
	name: 'Trainings',	
	
	data(){
		return {
			slug: '',
			title: '',
			slug: '',
			level: '',
			period: '',
			start_date: '',
			price: '',
			trainings: [
				{
					id: 1,
					title: "Web Frontend Development",
					slug: "web-frontend-development",
					level: "Beginner",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00"
				},
				{
					id: 2,
					title: "Backend Development",
					slug: "backend-development",
					level: "Beginner",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00"
				},
				{
					id: 3,
					title: "Backend Development - Advanced",
					slug: "backend-development-advanced",
					level: "Advanced",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00"
				},
				{
					id: 4,
					title: "Mobile Application Development with React Native - Advanced",
					slug: "mobile-application-development-with-react-native-advanced",
					level: "Advanced",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N250,000.00"
				}				
			]
		};
	},
	methods: {
		getTrainingData: () => {
			return this.trainings.filter( training => training.slug == this.slug );
		}
	},	
	mounted(){
		( async() => {
			const trainingData 	= await this.trainings.find( training => training.slug == this.$route.params.slug );
			this.title 			= trainingData.title;
			this.slug 			= trainingData.slug;
			this.level 			= trainingData.level;
			this.period 		= trainingData.period;
			this.start_date 	= trainingData.start_date;			
			this.price 			= trainingData.price;			
		} )();		
	}
}
</script>