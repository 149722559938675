<template>
	<div class="row justify-content-center mt-5">		
	  
		<div class="col-md-7 mb-5">
			
			<div class="">
				<h3 class="theme mt-0">
					<span class="theme-color">
						{{ solution }} 
					</span>
					<br>
					Quote Request Form				
				</h3>
				
				<div style="margin-top:0rem"> 
					<form @submit.prevent="submitForm">
						<input type="hidden" :value="solution" />
						<div class="row">
							<div class="col-md-6">
								<small class="field-label">
									First Name <span class="asterik">*</span>
								</small>
								<div class="form-group">
									<input type="text" class="form-control" v-model="firstname" ref="firstname" name="firstname" placeholder="First name" />
								</div>
								<span v-if="firstnameError" class="errorText">
									Enter first name
								</span>
							</div>
							<div class="col-md-6">
								<small class="field-label">
									Last Name
								</small>
								<div class="form-group">
									<input type="text" class="form-control" v-model="lastname" ref="lastname" name="lastname" placeholder="Last name" />
								</div>
							</div>							
						</div>
						
						
						<small class="field-label">
							Email  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="email" class="form-control" v-model="email" ref="email" name="email" placeholder="Email address" />
						</div>
						<span v-if="emailError" class="errorText">
							Enter email address
						</span>
						
						<small class="field-label">
							Phone Number  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="tel" class="form-control" v-model="phone" ref="phone" name="phone" placeholder="Phone number" />
						</div>
						<span v-if="phoneError" class="errorText">
							Enter phone
						</span>
				
						<small class="field-label">
							Additional Information
						</small>
						<div class="form-group">
							<textarea class="form-control" v-model="additional_information" ref="additional_information" name="additional_information" placeholder="Additional Information"></textarea>
						</div>
						
				
						
								
						<div class="form-group">
							<button v-if="!processing" type="submit" class="btn btn-primary">
								Request Quote
							</button>
							<button v-if="processing" type="submit" class="btn btn-primary">
								Processing ...
							</button>
							
						</div>
					</form>
				</div>   
			</div>
		</div>
		
	  
  </div>
  
  
  
  
 
  
</template>

<script>
import Cookies from 'js-cookie';
import { graphqlNoAuthRequest } from '../utils/client';
export default {
	name: 'GetQuote',
	data(){
		return {
			firstname: '',
			firstnameError: false,
			email: '',
			emailError: false,
			phone: '',
			phone: '',
			phoneError: false,
			additional_information: '',
			solution: null,
			processing: false,
			solutions: {
				"web-application-development": "Web Development and Related Solutions",
				"mobile-application-development": "Mobile Application and Related Solutions",
				"on-premise-cloud-deployment": "On-Premise Cloud Deployment and Related Solutions",
			}
		}
	},
	methods: {
		submitForm(){
		
			this.firstname 	= DOMPurify.sanitize( this.firstname );
			this.lastname 	= DOMPurify.sanitize( this.lastname );
			this.email 		= DOMPurify.sanitize( this.email );
			this.phone 		= DOMPurify.sanitize( this.phone );
			this.additional_information 		= DOMPurify.sanitize( this.additional_information );
		
			let isValidSubmission = true;
			
			if( this.firstname == '' ){
				this.firstnameError = true;
				isValidSubmission = false;
			}else{
				this.firstnameError = false;
			}		
	
			if( this.email == '' ){
				this.emailError = true;
				isValidSubmission = false;
			}else{
				this.emailError = false;
			}
			
			if( this.phone == '' ){
				this.phoneError = true;
				isValidSubmission = false;
			}else{
				this.phoneError = false;
			}		
	
			if( isValidSubmission ){
				this.processing = true;
				( async() => {
					try{
						const queryString = `mutation( $firstname: String!, $lastname: String, $email: String!, $phone: String!, $additional_info: String, $solution: String! ){
							createQuoteRequest( firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, additional_info: $additional_info, solution: $solution ){
								id
								firstname
								lastname
								email
								phone
								additional_info
								solution
							}
						}`;
						
						const variable = {
							firstname: this.firstname,
							lastname: this.lastname,
							email: this.email,
							phone: this.phone,
							additional_info: this.additional_information,
							solution: this.solution
						};
						
						const request = await graphqlNoAuthRequest( queryString, variable );
						if( request.error ){
							console.log( request );
							//	console.log( JSON.parse( request.error ) );
						}else{
							console.log( request );
							Cookies.set( 'quote', JSON.stringify( request.data ), { expires: ( 1/24 ) } );
													
							setTimeout( () => {
								this.processing = false;
								this.$router.push( '/quote-request-status' );
							}, 3000 );
							
						
						}
						
					}catch( e ){
						console.log( e );
					}
					
				
				} )();		
				
			}
			
		}
	},
	mounted(){
		( async() => {
			this.solution 	= await this.solutions[ this.$route.params.slug ];			
		} )();		
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

body{
	background-image: url( ../assets/about-us.png ) !important;
}


.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #444;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #444;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	

.field-label{
	color: #fff;
}

input, select, button{
	display: block;
	padding: 0 14px;
	height: 42px;
	width:100%;
	outline: none !important;
}


textarea{
	min-height: 210px;
}

button.btn-primary{
	margin-top:2rem;
	background-color: #008080;
	border:1px solid #008080;
	color: #fff;
}

.form-group{
	margin-bottom: 0px !important;
}

.asterik{
	color: #f00;
}

.field-label{
	display: inline-block;
	width: 100%;
	margin-top: 1rem;
}

.errorText{
	color: #f00;
	font-size: 12px;
	margin-top: 0px !important;	
}
.default-color{
	color: #444
}

</style>
