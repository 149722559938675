import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Solution from '../views/Solution.vue';
import Job from '../views/Job.vue';
import JobApplicationStatus from '../views/JobApplicationStatus.vue';
import Trainings from '../views/training/Trainings.vue';
import Training from '../views/training/Training.vue';
import TraineeLogin from '../views/training/TraineeLogin.vue';
import GetQuote from '../views/GetQuote.vue';
import QuoteRequestStatus from '../views/QuoteRequestStatus.vue';
import GetInTouch from '../views/GetInTouch.vue';
import GetInTouchStatus from '../views/GetInTouchStatus.vue';
import TermsAndConditionPage from '../views/TermsAndConditions.vue';
import PrivacyPolicyPage from '../views/PrivacyPolicy.vue';
import RefundPolicyPage from '../views/RefundPolicy.vue';
const isAuthenticated = false;

const routes = [
	{
		path: "/",
		redirect: '/home'
	},
	{
		path: "/home",
		name: "Home",
		component: Home
	},
	{
		path: "/about-us",
		name: "About",
		component: About
	},	
	{
		path: "/solutions",
		name: "Solution",
		component: Solution
	},	
	{
		path: "/solutions/:slug",
		name: "GetQuote",
		component: GetQuote
	},	
	{
		path: "/job",
		name: "Job",
		component:  Job
	},
	{
		path: "/job-application-status",
		name: "JobApplicationStatus",
		component:  JobApplicationStatus
	},
	{
		path: "/quote-request-status",
		name: "QuoteRequestStatus",
		component:  QuoteRequestStatus
	},
	{
		path: "/trainings",
		name: "Trainings",
		component: Trainings
	},
	{
		path: "/training/:slug",
		name: "Training",
		props: true,
		component: Training
	},
	{
		path: "/trainee/login",
		name: "TraineeLogin",
		props: true,
		component: TraineeLogin
	},
	{
		path: "/get-in-touch",
		name: "GetInTouch",
		props: true,
		component: GetInTouch
	},
	{
		path: "/get-in-touch-status",
		name: "GetInTouchStatus",
		props: true,
		component: GetInTouchStatus
	},
	{
		path: "/terms-and-conditions",
		name: "TermsAndCondition",
		props: true,
		component: TermsAndConditionPage
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		props: true,
		component: PrivacyPolicyPage
	},
	{
		path: "/refund-policy",
		name: "RefundPolicy",
		props: true,
		component: RefundPolicyPage
	},
	{
		path: '/trainee/dashboard',
		name: 'TraineeDashboard',
		component: () => import( '../views/training/TraineeDashboard.vue' ),
		beforeEnter: ( to, from, next ) => {
		  if ( !isAuthenticated ) {
			next( '/trainee/login' );
		  } else {
			next();
		  }
		}
	},
	{
		path: '/:catchAll(.*)',
		name: "NotFound",
		component: () => import( '../views/404.vue' )
	}
];

const router = createRouter({
	history: createWebHistory( process.env.BASE_URL ),
	routes
});

export default router;
