<template>
	<div class="container">
		<Job />
	</div>
	
</template>

<script>

import Job from '../components/Job.vue';

export default {
	name: 'JobComponent',
	components: {
		Job
	},	
	data(){
		return {};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>
<style scoped>
body{
	background-image: url( ../assets/about-us.png ) !important;
}

</style>