<template>
	<div class="container">
		<h1 class="sub-slide-theme mt-3 mb-2">
			Top Trending Trainings from 
			<span class="lato-bold">Westribbon</span> Lab
		</h1>
		
		<div class="row">
			<div class="col-md-3 card-col" v-for="training in homeTrainings" :key="training.id">
				<div class="cards" :data="training.bg">
					<div class="training-info">
						<div class="training-content">
							<h3 class="training-title">
								{{ training.title }}
							</h3>
							<p class="training-date">
								<small>Level: {{ training.level }}</small>
								<span class="mx-2">
									|
								</span>
								<small>Stack: {{ training.stack }}</small>
							</p>
							
							<!--
							<p class="training-date">
								Starting {{ training.start_date }}
							</p>
							-->
							
							<p class="training-period mt-2">
								Period: {{ training.period }}
							</p>
							
							<div class="training-level-price lato-bold">
								<small>{{ training.price }}</small>
							</div>
							
							<div class="btn-position">
								<span class="btn-wrapper">
									<router-link :to="{ name: 'Training', params:{ slug: training.slug }  }">
										More info ...
									</router-link>	
								</span>	
							</div>	
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	

</template>

<script>
export default {
	name: 'Trainings',	
	data(){
		return {
			trainings: [
				{
					id: 1,
					title: "Web Frontend Development",
					slug: "web-frontend-development",
					level: "Beginner",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product1.png",
					show_on_home: true
				},
				{
					id: 2,
					title: "Backend Development",
					slug: "backend-development",
					level: "Beginner",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product2.png",
					show_on_home: true
				},
				{
					id: 3,
					title: "Advanced Backend Development",
					slug: "backend-development-advanced",
					level: "Advanced",
					stack: "JavaScript",
					period: "3 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					bg: "../../assets/product3.png",
					show_on_home: true
				},
				{
					id: 3,
					title: "Advanced Full Stack Web Development",
					slug: "backend-development-advanced",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N500,000.00",
					show_on_home: false
				},
				{
					id: 4,
					title: "Mobile Application Development with React Native",
					slug: "mobile-application-development-with-react-native",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N350,000.00",
					bg: "../../assets/product4.png",
					show_on_home: true
				},
				{
					id: 4,
					title: "GraphQL Development with JavaScript",
					slug: "mobile-application-development-with-react-native",
					level: "Advanced",
					stack: "JavaScript",
					period: "6 Months",
					start_date: "21St, July 2024",
					price: "N150,000.00",
					show_on_home: false
				}				
			]
		};
	},
	methods: {
	
	},
	computed: {
		homeTrainings(){
			let data = this.trainings.reverse().filter( training => training.show_on_home  );				
			return data.reverse();				
		}
	},
	mounted(){
		return {};
	}, 
	
	setup(){
		
	},
}



</script>

<style>


</style>