const stringify = (obj) => {
	let cache 	= [];
	let str 	= JSON.stringify( obj, ( key, value ) => {
		if ( typeof value === "object" && value !== null ) {
			if ( cache.indexOf( value ) !== -1 ) {
				return;
			}
			cache.push( value );
		}
		return value;
	});
	cache = null;
	return str;
}

export async function graphqlNoAuthRequest( graphql_string, variablesObject ){
		
	let headers = {
		"Content-Type": "application/json"
	}
	
	let requestBody = { 
		query : graphql_string
	};	
	
	const postData = variablesObject || {};
	if( postData !== {} ){
		requestBody = { 
			query : graphql_string,
			variables : postData
		};
	};
	console.log( process.env.VUE_APP_GRAPHQL_URI );
	const reqBody 	= stringify( requestBody );	
	const response 	= await fetch( process.env.VUE_APP_GRAPHQL_URI, {	
		method: "POST",
		headers,
		body: reqBody,
	});
	
	let payload = {
		data: null,
		error: null,
		processing: true
	};
	
	const responseObj = await response.json();	
	if( responseObj.errors ){
		console.log( responseObj.errors[0].message );
		payload['error'] = responseObj.errors[0].message;
		payload['processing'] = false;
	}
	
	if( !responseObj.errors ){
		payload['data'] = responseObj.data;
		payload['processing'] = false;
	}
	
	return payload;
	
}


export async function  graphqlAuthRequest( request, graphql_string, variablesObject ){
	
	const authorization = request.headers?.authorization;
	if( !authorization ) {
		throw new Error( 'Not authorized due to absence of valid token!' );		
	} 
	
	let headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
	}
	
	headers[ 'Authorization' ] = authorization;
	
	let requestBody = { 
		query : graphql_string
	};	
	
	const postData = variablesObject || {};
	if( postData !== {} ){
		requestBody = { 
			query : graphql_string,
			variables : postData
		};
	};
	
	const response = await fetch( process.env.VUE_APP_GRAPHQL_URI, {
	//	const response = await fetch( "http://localhost:4110", {
		method: "POST",
		headers,
		body: stringify( requestBody ),
	});
	
	let payload = {
		data: null,
		error: null,
		processing: true
	};
	
	const responseObj = await response.json();	
	if( responseObj.errors ){
		console.log( responseObj.errors[0].message );
		payload['error'] = responseObj.errors[0].message;
		payload['processing'] = false;
	}
	
	if( !responseObj.errors ){
		payload['data'] = responseObj.data;
		payload['processing'] = false;
	}
	
	return payload;
	
}


export async function graphqlTokenAuthRequest( token, graphql_string, variablesObject ){
	
	const validToken = token || null;
	if( !validToken ) {
		throw new Error( "Request not authorized due to absence of valid token!" );		
	} 
	
	let headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
	}
	
	headers['Authorization'] = "Bearer " + token;
	
	let requestBody = { 
		query : graphql_string
	};	
	
	const postData = variablesObject || {};
	if( postData !== {} ){
		requestBody = { 
			query : graphql_string,
			variables : postData
		};
	};
	
	const response = await fetch( process.env.VUE_APP_GRAPHQL_URI, {
	//	const response = await fetch( "http://localhost:4110", {
		method: "POST",
		headers,
		body: stringify( requestBody ),
	});
	
	let payload = {
		data: null,
		error: null,
		processing: true
	};
	
	const responseObj = await response.json();	
	if( responseObj.errors ){
		console.log( responseObj.errors[0].message );
		payload['error'] 		= responseObj.errors[0].message;
		payload['processing'] 	= false;
	}
	
	if( !responseObj.errors ){
		payload['data'] 		= responseObj.data;
		payload['processing'] 	= false;
	}
	
	return payload;
	
}


