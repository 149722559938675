<template>
	<div class="container">
		<GetInTouch />
	</div>
	
	<div style="margin-top:7rem;">
	</div>
</template>

<script>

import GetInTouch from '../components/GetInTouch.vue';

export default {
	name: 'GetInTouchPage',
	components: {
		GetInTouch
	},	
	data(){
		return {};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>
<style scoped>
body{
	background-image: url( ../assets/about-us.png ) !important;
}

</style>