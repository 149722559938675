<template>
  <div class="row justify-content-center mt-5">  
		<div class="col-md-9">
			<div class="">
				<h1 class="theme">
					Refund Policy
				</h1>
				
				
				<p>					
					At Westribbon, we strive to ensure your satisfaction with 
					our electronic content services. If you are not entirely 
					satisfied with your purchase, we’re here to help.

				</p>
				
				<h4 class="theme">
					1. Refund Eligibility
				</h4>
				
				
				<ul class="list">
					<li>
						<strong>Digital Products</strong>: Refunds for digital products,
						such as eBooks, software, or downloadable content, are only 
						available if the product is found to be defective or not as 
						described.
					</li>					
					<li>					
						<strong>Subscription Services</strong>: Refunds for subscription 
						services may be granted if requested within 60
						days of the initial purchase, provided you have not excessively 
						used the service.
					</li>
				</ul> 
				
				<h4 class="theme">
					2. Requesting a Refund
				</h4>
				<p>
					To request a refund, please contact our customer service team at 
					hi@westribbon.com.ng with your order details, including 
					the order number and a description of the issue.
				</p>
				 
				
				<h4 class="theme">
					3. Processing Refunds
				</h4>				
				<ul class="list">
					<li>
						Once we receive your refund request, we will review and notify 
						you of the status within 30 business days.
					</li>					
					<li>
						If your refund is approved, we will initiate a refund to your 
						original method of payment. You will receive the credit within 
						a certain number of days, depending on your card issuer’s 
						policies.
					</li>
				</ul> 
				
				
				
				<h4 class="theme">
					4. Non-Refundable Items
				</h4>				
				<ul class="list">
					<li>
						<strong>Promotional Offers</strong>: Items purchased during a 
						sale or with a promotional discount are non-refundable.
					</li>					
					<li>
						<strong>Gift Cards</strong>: Gift cards and prepaid cards are 
						non-refundable.
					</li>					
					<li>
						<strong>Excessive Use</strong>: Refunds will not be granted 
						for services or content that have been excessively used or 
						consumed.
					</li>
				</ul> 
				
				<h4 class="theme">
					5. Subscriptions and Cancellations
				</h4>				
				<ul class="list">
					<li>
						<strong>Monthly Subscriptions</strong>: 
						If you cancel your monthly subscription, you will not be charged 
						for the following month. However, no partial refunds will be 
						issued for the remaining days of the current month.
					</li>					
					<li>
						<strong>Annual Subscriptions</strong>: 
						If you cancel your annual subscription within 2
						days of purchase, you may be eligible for a prorated refund based 
						on the remaining months of the subscription period.

					</li>
				</ul> 
				
				
				<h4 class="theme">
					6. Contact Us
				</h4>
				<p>
					If you have any questions or need assistance with your refund, 
					please contact us at hi@westribbon.com.ng.

				</p>
				  
				<h4 class="theme">
					7. Changes to This Policy
				</h4>
				<p>
					Westribbon reserves the right to modify this Refund Policy at any 
					time. Any changes will be effective immediately upon posting on 
					our website.

				</p>
				  
			</div>
		</div>
		
	  
  </div>
  
 
  
</template>

<script>
export default {
  name: 'RefundPolicy'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #fff;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #eee;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	


</style>
