<template>
  <div class="row justify-content-center mt-5">		
	  
		<div class="col-md-4 mb-5">
			
			<div class="">
				<h3 class="theme mt-0">
					<span class="theme-color">
						Trainee Login
					</span>
					Form				
				</h3>
				
				<div style="margin-top:0rem"> 
					<form @submit.prevent="submitForm">
						
						<small class="field-label">
							Email  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="email" class="form-control" v-model="email" ref="email" name="email" placeholder="Email address" />
						</div>
						<span v-if="emailError" class="errorText">
							Enter email address
						</span>
						
						<small class="field-label">
							Password  <span class="asterik">*</span>
						</small>
						<div class="form-group">
							<input type="password" class="form-control" v-model="password" ref="password" name="password" placeholder="Password" />
						</div>
						<span v-if="passwordError" class="errorText">
							Enter password
						</span> 
						
						<div class="form-group">
							<button v-if="!processing" type="submit" class="btn btn-primary">
								Login
							</button>
							<button v-if="processing" type="submit" class="btn btn-primary">
								Processing ...
							</button>
						</div>
					</form>
					
					<!--
					<div class="mt-5">
						<img src="../assets/about-us-1.png" style="width:100%" />
					</div> 
					-->
				</div>   
			</div>
		</div>
		
	  
  </div>
  
 
  
</template>

<script>
import { graphqlNoAuthRequest } from '../utils/client';
export default {
  name: 'Job',
  data(){
	return {
		email: '',
		emailError: false,
		password: '',
		passwordError: false,
		processing: false
	}
  },
  methods: {
	submitForm(){
		let isValidSubmission = true;
		
		if( this.email == '' ){
			this.emailError = true;
			isValidSubmission = false;
		}else{
			this.emailError = false;
		}
		
		if( this.password == '' ){
			this.passwordError = true;
			isValidSubmission = false;
		}else{
			this.passwordError = false;
		}		

		if( isValidSubmission ){
			this.processing =  true;
			 
			//	( async() => {
			//		try{
			//			const queryString = `mutation( $email: String!, $password: String! ){
			//				login( email: $email, password: $password ){
			//					id
			//					firstname
			//					lastname
			//					email
			//					phone
			//				}
			//			}`;
			//			
			//			const variable = {
			//				email: this.password,
			//				password: this.password
			//			};
			//			
			//			//	const request = await graphqlNoAuthRequest( queryString, variable );
			//			//	if( request.error ){
			//			//		console.log( JSON.parse( request.error ) );
			//			//	}else{
			//			//		console.log( request );
			//			//	}
			//			
			//		}catch( e ){
			//			console.log( e );
			//		}
			//		
			//	
			//	} )();

			setTimeout( () => {
				this.processing =  true;
				this.$router.push('/about-us');
			}, 3000 );	

			
			
		}
		
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

body{
	background-image: url( ../assets/about-us.png ) !important;
}


.theme{
	color: #008080;
	color: #fff;
	margin:2rem 0 !important;
}

.theme-color{
	color: #008080;	
}

p{
	margin-bottom: 1rem !important;
	color: #444;
	font-size:18px;
}

.list{
	margin:1rem;
	padding:0;
}	

.list li{
	color: #444;
	list-style-image:  url( ../assets/bullet.png );
	font-size:14px;
}	

.field-label{
	color: #fff;
}

input, select, button{
	display: block;
	padding: 0 14px;
	height: 42px;
	width:100%;
	outline: none !important;
}

button.btn-primary{
	margin-top:2rem;
	background-color: #008080;
	border:1px solid #008080;
	color: #fff;
}

.form-group{
	margin-bottom: 0px !important;
}

.asterik{
	color: #f00;
}

.field-label{
	display: inline-block;
	width: 100%;
	margin-top: 1rem;
}

.errorText{
	color: #f00;
	font-size: 12px;
	margin-top: 0px !important;	
}
.default-color{
	color: #444
}

</style>
