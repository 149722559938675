<template>
	<div class="container">
		<QetQuote />
	</div>
	
	<div style="margin-top:7rem;">
	</div>
	<Solution />
</template>

<script>

import QetQuote from '../components/QetQuote.vue';
import Solution from '../components/Solution.vue';

export default {
	name: 'GetQuotePage',
	components: {
		QetQuote, Solution
	},	
	data(){
		return {};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>
<style scoped>
body{
	background-image: url( ../assets/about-us.png ) !important;
}

</style>