<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-6 mt-5" style="min-height:350px;">
				<div class="card p-3" style="border-left:7px solid #008080;">
					<h4 class="centered-text mb-2">
						Get in Touch Status
					</h4>
					<hr />
					
					<!---->
					<p class="centered-text">
						Hello <strong>{{contact.firstname}} {{contact.lastname}}</strong>,
					</p>
					
					
					<p class="centered-text">
						This is to let you know we have received your message 
						and that we really appreciate your interest	in us.
					</p>
					<p class="centered-text mt-3">			
						We will be in touch soon.
					</p>
					<hr />
					<h6 class="centered-text mb-2">
						<strong style="color:#008080">
							Westribbon Team
						</strong>
					</h6>					
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
	//	name: "GetInTouchStatus",
	data(){
		return {
			contact: JSON.parse( Cookies.get( 'contact' ) ).createGetInTouch,			
		}
	},
	setup(){
	
	},
	mounted() {
		return {}
	},
	
}
</script>

<style scoped>
.centered-texts{
	text-align: center;
}

</style>