<template>
	<div class="container">
		<TraineeLogin />
	</div>
	
</template>

<script>

import TraineeLogin from '../../components/TraineeLogin.vue';

export default {
	name: 'TraineeLoginPage',
	components: {
		TraineeLogin
	},	
	data(){
		return {
			processing: false
		};
	},
	methods: {
	
	},
	computed: {
		homeData(){
			let data = [];				
			return data.reverse();				
		}
	},
	mounted(){
	
	}, 
	setup(){
	
	}
}



</script>
<style scoped>
body{
	background-image: url( ../../assets/about-us.png ) !important;
}

</style>